import React, {useEffect} from 'react';
import axios from 'axios';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LOGIN, validToken} from '~/features/Login/slice';
import Employ from '~/forms/page/Write/Employ';
import About from '~/forms/page/About';
import Fnq from '~/forms/page/Fnq';
import Newitem from '~/forms/page/Write/NewItem';
import Myitem from '~/forms/page/Write/Myitem';
import NewContent from '~/components/NewContent';
import ConfirmItem from '~/forms/page/Write/ConfirmItem';
import Mainpage from '~/forms/Mainpage';
import {RootState} from './rootReducers';
import {useTranslation} from 'react-i18next';
import Community from '~/forms/page/Write/Community';
import Feedback from '~/forms/page/Feedback';

function App() {
  const dispatch = useDispatch();
  const {lang} = useSelector((root: RootState) => root[LOGIN]);
  const {i18n: i18next} = useTranslation();

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, [lang, i18next]);

  useEffect(() => {
    dispatch(validToken());
  }, [dispatch]);

  useEffect(() => {
    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        //config.headers.Authorization = `Bearer ${token}`;
        config.baseURL = 'https://strapi-vrf6.onrender.com';
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/:test" component={PaginationComponent} /> */}
        <Route path="/" exact component={Mainpage} />
        <Route path="/community" component={Community} />
        <Route path="/about" component={About} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/fnq" component={Fnq} />
        <Route path="/info" exact component={Employ} />
        <Route path="/board" exact={true} component={Newitem} />
        <Route path="/myitem" exact={true} component={Myitem} />
        <Route path="/confirm/:id" component={ConfirmItem} />
        <Route path="/:id" component={NewContent} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
