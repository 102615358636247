import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {resources} from './i18n/config';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import createStore from './app/store';
import history from './utils/history';

const {store, persistor} = createStore();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'ko',
    fallbackLng: 'ko',
    interpolation: {
      escapeValue: false,
    },

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
