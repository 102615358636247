import {
  Button,
  CircularProgress,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import SelectRegion from '~/forms/page/Select/SelectRegion';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import _ from 'lodash';
import {SIDO} from '~/features/Sido/slice';
import moment from 'moment';
import {createBoardId, IBoard} from '~/features/Board/slice';
import {Tabs} from '@material-ui/core';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import Topadinfo from '~/components/Topadinfo';
import TopIcon from '~/image/Top.png';
import './style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {loadPost, POST} from '~/features/Post/slice';
import {LOGIN, setLang, LangCode} from '~/features/Login/slice';
import {useTranslation} from 'react-i18next';
import {ITEMS_PER_PAGE} from '~/contants/Pagination';
import clsx from 'clsx';
import {VERIFY} from '~/features/Verify/slice';
import NewitemModal from '~/components/Newitemmodal';
import usePagination from '~/hooks/usePagination';

const useStyles = makeStyles((theme) => ({
  // Middle
  listul: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto ',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 360,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0',
      margin: '0 auto',
      background: '#FFF',
      justifyContent: 'center',
    },
  },

  pageUl: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto 130px',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      padding: '0',
      margin: '0 auto',
      background: '#FFF',
      justifyContent: 'center',
    },
  },

  listli: {
    padding: '16px, 0 0 0',
    // float: 'left',
    listStyle: 'none',
    '.MuiTabs-indicator': {
      display: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  listlink: {
    padding: '0px 15px !important',
    fontSize: '12px!important',
    color: '#333!important',
    fontFamily: 'Gulim, Tahoma, Arial, Verdana, Helvetica, sans-serif',
    float: 'left',
    opacity: '1!important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 12px!important',
      fontWeight: '600!important',
    },
  },

  topad: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',
    padding: '15px 0px 0px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      padding: '0',
      margin: '0 auto',
    },
  },
  bottomad: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
    },
  },

  bottomadpr: {
    marginTop: 15,
    borderTop: '0.5px solid #979696',
    borderBottom: '0.5px solid #979696',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '11px 15px 4px',
      borderBottom: '0',
    },
  },

  mobileFixed: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      top: '0',
    },
  },

  test: {
    display: 'none',
  },

  selected: {
    color: '#ff3900 !important',
  },

  topPage: {
    width: '100%',
    maxWidth: 980,
    left: '230px',
    background: '#fffcf9',
    borderTop: '0.5px solid #979696',
    borderBottom: '0.5px solid #979696',
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '12px',
    margin: '0 auto',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      borderTop: '0',
      borderBottom: '0.5px solid #eee',
      padding: '18px 15px 10px',
    },
  },

  topAdTag: {
    lineHeight: '16px',
    color: '#FB2E2E',
    margin: 'auto 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 18px 0px 0px',
      margin: '0',
    },
  },

  searchform: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    margin: '13px 12px 14px auto',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  searchipt: {
    width: '109px',
    height: '20px',
    lineHeight: '23px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '0',
    float: 'left',
    margin: '0 -1px 0 0',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  searchbtn: {
    minWidth: '50px',
    height: '22px',
    float: 'left',
    border: '1px solid #ccc',
    background: '#ddd',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '11px',

    '&:hover': {
      backgroundColor: '#ddd',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  layout: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '12px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      margin: '0 auto',
    },
  },

  selectbar: {
    border: '1px solid #ccc',
    background: '#daebf0',
    padding: '0 4px',
    display: 'inline-flex',
    width: '100%',
    maxWidth: 970,
    height: '47px',

    [theme.breakpoints.up(MobileWeb.mobile)]: {
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      height: '72px',
      // background: '#eee',
      border: '0px',
      padding: '0px',
    },

    [theme.breakpoints.down(484)]: {
      display: 'flex',
      alignItems: 'center',
      height: 90,
      justifyContent: 'space-between',
    },
  },
  pageMobile: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      margin: '0 auto',
      fontSize: '13px',
      display: 'inline',
    },
  },

  topBtnMobile: {
    border: '1px solid: #979696',
    background: '#F0EFEF',
    color: '#979696',
    borderRadius: 0,
    minWidth: '75px',
    height: '24px',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },
  onlyMobileButton: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      width: 'auto',
      height: '30px',
      border: '1px solid: #E5E5E5',
      background: '#F0EFEF',
      color: '#979696',
      borderRadius: 0,
      fontSize: '11px',
      margin: '6px 0 52px',
    },
  },

  searchInputMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      width: '271px',
      height: '26px',
      border: '1px solid #E0E0E0',
    },
  },

  searchBtnMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      minWidth: '57px',
      height: '30px',
      fontSize: '11px',
      border: '1px solid #E0E0E0',
      background: '#F0EFEF',
    },
  },

  topAd: {
    paddingBottom: '7px',
    alignItems: 'center',
    display: 'inline-flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  topAdDiv: {
    display: 'flex',
    cursor: 'pointer',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // maxHeight: '13px',
      fontSize: '12px',
      height: '15px',
    },
  },

  nonTopAdDiv: {
    display: 'flex',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // maxHeight: '13px',
      fontSize: '12px',
      height: '15px',
    },
  },

  topAdTitle: {
    paddingRight: '10px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  topAdSido: {
    paddingRight: '10px',
    color: '#aaa',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      paddingRight: '0px',
    },
  },
  paging: {
    margin: '10px 3.5px 120px 0px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '15px',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '10px 3.5px 11.5px 0px',
    },
  },

  pagingMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: ' 60px',
      margin: '10px 3.5px 0px 0px',
      display: 'flex',
    },
  },

  pagingPrev: {
    marginRight: '3.5px',
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  pagingNext: {
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  onlyPcbtn: {
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectlist: {
    display: 'flex',
    alignSelf: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginLeft: '12px',
    },

    [theme.breakpoints.down(484)]: {
      flexDirection: 'column',
      maxWidth: 220,
    },
  },

  ptag: {
    display: 'flex',
    margin: '0 22px 0 27px',
    float: 'left',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectbox: {
    width: '118px',
    height: '20px',
    float: 'left',
    listStyle: 'none',
    marginRight: '9px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '162px',
      height: '25px',
      marginRight: '9px',
    },
  },

  selectprop: {
    width: '100%',
    height: '20px',
    lineHeight: '20px',
    // margin: '1px 5px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '23px',
    },
  },

  postDiv: {
    display: 'flex',
    cursor: 'pointer',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '15px',
      fontSize: '12px',
    },
  },

  nonPostDiv: {
    display: 'flex',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '15px',
      fontSize: '12px',
    },
  },

  buttonProgress: {
    color: 'gray',
  },

  postTitle: {
    minWidth: 155,
    paddingRight: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  catDiv: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#aaa',
    width: '30%',
    minWidth: 140,
    [theme.breakpoints.up(MobileWeb.mobile)]: {},
  },

  userpr: {
    // background: '#ff3900!important',
    fontWeight: 'bold',
    color: '#ff3900!important',
    padding: '0!important',
    margin: '0!important',
    float: 'left',
    textDecorationLine: 'none',
    boarderRadius: 'none',
    // width: '78px',
    height: '30px',
    borderRadius: '0!important',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  userp: {
    color: '#000!important',
    fontWeight: 'bold',
    padding: '5!important',
    // border: '1px solid #ccc!important',
    // background: '#FFF!important',
    height: '30px',
    borderRadius: '0!important',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  postButtonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      flexDirection: 'column',
      minWidth: 130,
    },
    [theme.breakpoints.down(484)]: {
      flexDirection: 'column',
      minWidth: 130,
    },
  },
  //
}));

interface IState {
  mode?: number;
}

const Employ = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page')
    ? Number(queryParams.get('page'))
    : 1;
  const sidoCode = queryParams.get('sido');
  const siggCode = queryParams.get('siggCode');
  const boardId =
    Number(queryParams.get('boardId')) === 0
      ? 1
      : Number(queryParams.get('boardId'));

  const mode = (location.state as IState)?.mode;

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(MobileWeb.mobile));
  const {t} = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {lang} = useSelector((root: RootState) => root[LOGIN]);

  // const [boardId, setBoardId] = useState<number>(
  //   mode === 1 || mode === 2 ? mode : 1,
  // );
  const [value, setValue] = useState(mode === 2 ? 1 : 0);
  // const [sidoCode, setSidoCode] = useState(
  //   mode !== 1 && mode !== 2 ? `${mode}` : '0',
  // );
  // const [siggCode, setSiggCode] = useState('0');
  const [testList, setTestList] = useState<IBoard[]>();
  const [catList, setCatList] = useState<IBoard[]>();
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitPost, setLimitPost] = useState(0);
  const [headerLang, setHeaderLang] = useState<LangCode>();

  // PagiNation

  // 게시글이 보여지는 범위 ex) 0 은 리스트의 배열 0번
  // const [pageValue, setPageValue] = useState(0);
  // 게시글 리스트 배열
  // const [pageList, setPageList] = useState<IBoard[]>();
  // 게시글 버튼 숫자
  // const [currentPage, setcurrentPage] = useState(
  //   queryParams.get('page') ? Number(queryParams.get('page')) : 1,
  // );

  // 최대 게시글 / ITEMS_PER_PAGE(보여지는 게시글 수)을 소수점 반올림
  const pageNumberLimit = Math.ceil(limitPost / ITEMS_PER_PAGE);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  // 번역

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  // --------------Cat----------------------------- //

  // const [boardValue, setBoardValue] = useState('');
  const [cat1Value, setCat1Value] = useState('');
  const {postList} = useSelector((state: RootState) => state[POST]) ?? {};

  // Category

  const cat1 = useMemo(() => {
    return (
      <>
        <option value="">{t('분류선택')}</option>
        <option value="외식⠂식음료">{t('외식⠂식음료')}</option>
        <option value="판매⠂매장관리">{t('판매⠂매장관리')}</option>
        <option value="상담⠂영업">{t('상담⠂영업')}</option>
        <option value="사무직">{t('사무직')}</option>
        <option value="서비스직">{t('서비스직')}</option>
        <option value="생산⠂건설">{t('생산⠂건설')}</option>
        <option value="IT⠂디자인">{t('IT⠂디자인')}</option>
        <option value="교육⠂강사">{t('교육⠂강사')}</option>
      </>
    );
  }, [t]);

  const cats = useMemo(() => {
    if (boardId === 3 || boardId === 4) {
      return '';
    } else if (cat1Value !== '') {
      return `&cat1=${cat1Value}`;
    } else {
      return '';
    }
  }, [boardId, cat1Value]);

  // 글쓰기 추가
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};

  const [openModal, setOpenModal] = useState(false);

  const write = () => {
    history.push('/board');
  };

  const myitem = () => {
    history.push('/myitem');
  };

  // ---------------------------------------------- //

  // 최대 게시글

  useEffect(() => {
    const maxPosts = async () => {
      try {
        const resp = await axios.get(`${API_URI}/posts/count?board=${boardId}`);
        setLimitPost(resp.data);
      } catch (error) {}
    };
    maxPosts();
  }, [boardId]);

  //

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value);
  };

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const url = useMemo(() => {
    console.log(sidoCode);

    if (!sidoCode && (!siggCode || siggCode === '0')) {
      return '';
    } else if (
      sidoCode === '0' &&
      (siggCode === undefined || siggCode === '0')
    ) {
      return '';
    } else if (sidoCode === '0' && siggCode === undefined) {
      return '';
    } else if (sidoCode === '0' && siggCode === '0') {
      return '';
    } else if (sidoCode !== undefined && siggCode && siggCode !== '0') {
      return `&sido_code=${sidoCode}&sgg_code=${siggCode}`;
    } else if (sidoCode !== undefined && (!siggCode || '0')) {
      return `&sido_code=${sidoCode}`;
    }
  }, [sidoCode, siggCode]);

  const searchFilter = useCallback(async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `${API_URI}/posts?_limit=${limitPost}&board=${boardId}${url}${
            cats && cats
          }`,
        )
        .then((resp) => {
          setTestList(
            _.filter(resp.data, (prop) =>
              searchKey
                ? prop.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
                : true,
            ),
          );
        })
        .catch(() => {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [boardId, cats, searchKey, url, limitPost]);

  const postTopAd = useCallback(async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `${API_URI}/posts?_limit=${limitPost}&board=${boardId}&notice=true${url}${
            cats && cats
          }`,
        )
        .then((resp) => {
          setCatList(
            _.filter(resp.data, (prop) =>
              searchKey
                ? prop.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
                : true,
            ).reverse(),
          );
        })
        .catch(() => {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [boardId, cats, searchKey, url, limitPost]);

  const found = useMemo(() => {
    // return _.sortBy(catList, (up) => moment(up.updated_at)).reverse();
    return _.shuffle(catList);
  }, [catList]);

  const {sidoList} = useSelector((state: RootState) => state[SIDO]) ?? {};

  useEffect(() => {
    dispatch(createBoardId({id: Number(boardId) ?? 1}));
  }, [dispatch, boardId]);

  useEffect(() => {
    if (mobile) {
      setmaxPageNumberLimit(5);
    } else {
      setmaxPageNumberLimit(10);
    }
  }, [mobile]);

  useEffect(() => {
    const postFilter = async () => {
      setLoading(true);
      try {
        await axios
          .get(
            `${API_URI}/posts?_limit=${limitPost}&board=${boardId}${url}${
              cats && cats
            }`,
          )
          .then((resp) => {
            setTestList(_.filter(resp.data, (prop) => true));
          })
          .catch(() => {});
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    postFilter();
  }, [value, boardId, url, cats, setTestList, limitPost]);

  useEffect(() => {
    const postTopAd = async () => {
      setLoading(true);
      try {
        await axios
          .get(
            `${API_URI}/posts?_limit=${limitPost}&board=${boardId}&notice=true${url}${
              cats && cats
            }`,
          )
          .then((resp) => {
            setCatList(_.filter(resp.data, (prop) => true).reverse());
          })
          .catch(() => {});
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    postTopAd();
  }, [value, siggCode, sidoCode, boardId, url, cats, setTestList, limitPost]);

  const handleClick = (e: any) => {
    // setcurrentPage(Number(e.target.id));
    if (!sidoCode) {
      return history.push(`/info?boardId=${boardId}&page=${e.target.id}`);
    }

    history.push(
      `/info?boardId=${boardId}&page=${e.target.id}&sido=${sidoCode}${
        siggCode ? `&siggCode=${siggCode}` : ''
      }`,
    );
  };

  const bottomPost = _.sortBy(testList, (up) =>
    moment(up?.updated_at),
  ).reverse();

  const pages = [];
  if (bottomPost) {
    for (let i = 1; i <= Math.ceil(bottomPost.length / ITEMS_PER_PAGE); i++) {
      pages.push(i);
    }
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const currentItems =
    bottomPost && bottomPost.slice(indexOfFirstItem, indexOfLastItem);

  const {pageList} = usePagination({
    totalPage: Math.ceil(bottomPost.length / ITEMS_PER_PAGE),
    currentPage: currentPage,
    maxPageCount: mobile ? 5 : 10,
  });

  const renderPageNumbers = pageList.map((number) => {
    return (
      <li
        key={number}
        id={`${number}`}
        onClick={handleClick}
        className={`${currentPage === number ? 'active' : null}`}>
        {number}
      </li>
    );
  });

  // const renderPageNumbers = pages.map((number) => {
  //   if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
  //     return (
  //       <li
  //         key={number}
  //         id={`${number}`}
  //         onClick={handleClick}
  //         className={`${currentPage === number ? 'active' : null}`}>
  //         {number}
  //       </li>
  //     );
  //   } else {
  //     return null;
  //   }
  // });

  // Pagination 다음 버튼
  const handleNextbtn = () => {
    // setcurrentPage(currentPage + 1);

    if (!sidoCode) {
      return history.push(`/info?boardId=${boardId}&page=${currentPage + 1}`);
    }

    history.push(
      `/info?boardId=${boardId}&page=${currentPage + 1}&sido=${sidoCode}${
        siggCode ? `&siggCode=${siggCode}` : ''
      }`,
    );
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    // else {
    //   setPageValue((currentPage - 1) * ITEMS_PER_PAGE);
    // }
  };

  // Pagination 이전 버튼
  const handlePrevbtn = () => {
    if (!sidoCode) {
      return history.push(`/info?boardId=${boardId}&page=${currentPage - 1}`);
    }

    // setcurrentPage(currentPage - 1);
    history.push(
      `/info?boardId=${boardId}&page=${currentPage - 1}&sido=${sidoCode}${
        siggCode ? `&siggCode=${siggCode}` : ''
      }`,
    );

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    // else {
    //   setPageValue((currentPage - 1) * ITEMS_PER_PAGE);
    // }
  };

  const postFound = useMemo(() => {
    return _.sortBy(currentItems, (up) => moment(up.updated_at)).reverse();
  }, [currentItems]);

  const renderData = useCallback(
    (data: any[]) => {
      return (
        <>
          {_.map(data, (list) => {
            const boardSido = _.find(sidoList, function (o) {
              return `${o.code}` === list.sido_code;
            })?.name;

            const sigungu = _.find(sidoList, function (o) {
              return `${o.code}` === list.sido_code;
            })?.sigungus;

            const boardSigg = _.find(sigungu, function (o) {
              return `${o.code}` === list.sgg_code;
            })?.name;

            let title = list?.title;
            if (lang === 'ch' && list?.title_ch) {
              title = list?.title_ch;
            }
            if (lang === 'ru' && list?.title_ru) {
              title = list?.title_ru;
            }
            if (lang === 'vi' && list?.title_vi) {
              title = list?.title_vi;
            }

            return (
              <div
                className={classes.postDiv}
                onClick={() => {
                  history.push(`${list?.id}`);
                }}>
                <p style={{paddingRight: '10px', minWidth: 40}}>
                  {moment(list.updated_at).format('MM-DD')}
                </p>
                <p className={classes.postTitle}>{title}</p>
                <div className={classes.catDiv}>
                  {list?.cat1 ? (
                    <>
                      ({t(`${boardSido}`)} {t(`${boardSigg}`)}) -{' '}
                      {t(`${list?.cat1}`)}
                    </>
                  ) : (
                    <>
                      ({t(`${boardSido}`)} {t(`${boardSigg}`)})
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    },
    [
      classes.postDiv,
      history,
      lang,
      sidoList,
      classes.postTitle,
      t,
      classes.catDiv,
    ],
  );

  return (
    <div
      style={{
        marginBottom: 60,
      }}>
      <div className={classes.mobileFixed}>
        <Header
          langProp={(headerLang: LangCode) => {
            setHeaderLang(headerLang);
          }}
        />
        <div>
          <div className={classes.listul}>
            <Tabs
              variant="scrollable"
              scrollButtons="on"
              classes={{root: classes.listli, indicator: classes.test}}
              value={value}
              onChange={handleChange}>
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('채용정보')}
                onClick={() => {
                  history.push(`/info?page=1&boardId=1`);
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('인재공고')}
                onClick={() => {
                  history.push(`/info?page=1&boardId=2`);
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('단체 모임방')}
                onClick={() => {
                  history.push('/community', {mode: 3});
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('나의 일자리 경험담_')}
                onClick={() => {
                  history.push('/community', {mode: 4});
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('인사노무_')}
                onClick={() => {
                  history.push('/feedback', {mode: 5});
                }}
              />
            </Tabs>
          </div>
        </div>

        <div className={classes.layout}>
          <div className={classes.selectbar}>
            <div className={classes.selectlist}>
              <SelectRegion
                initSido={`${
                  Number(sidoCode) === 1 || Number(sidoCode) === 2
                    ? 0
                    : sidoCode
                }`}
                initSigg={siggCode}
                loadSido={(sidoCode: string) => {
                  // setSidoCode(sidoCode);

                  if (!sidoCode) {
                    return history.push(`/info?boardId=${boardId}&page=1`);
                  }

                  history.push(
                    `/info?boardId=${boardId}&page=1&sido=${sidoCode}`,
                  );
                }}
                loadSigg={(siggCode: string) => {
                  if (!siggCode) {
                    return history.push(
                      `/info?boardId=${boardId}&page=1&sido=${sidoCode}`,
                    );
                  }

                  history.push(
                    `/info?boardId=${boardId}&page=1&sido=${sidoCode}&siggCode=${siggCode}`,
                  );
                }}
              />

              <div>
                <p className={classes.ptag}>{t('업종')}</p>
                <li className={classes.selectbox}>
                  <select
                    className={classes.selectprop}
                    onChange={(e) => {
                      setCat1Value(e.target.value);
                    }}
                    // onClick={(e) => {
                    //   setBoardValue(`${boardId}`);
                    // }}
                  >
                    {cat1}
                  </select>
                </li>
              </div>
            </div>
            <div className={classes.postButtonGroup}>
              <div>
                <Button
                  classes={{root: classes.userp}}
                  onClick={() => {
                    user ? myitem() : setOpenModal(true);
                  }}>
                  {t('내가 쓴 글')}
                </Button>
              </div>
              <div style={{padding: '0 0 0 4px'}}>
                <Button
                  classes={{root: classes.userpr}}
                  onClick={() => {
                    user ? write() : setOpenModal(true);
                  }}>
                  {t('공고등록')}
                </Button>
              </div>
              <div className={classes.searchform} style={{paddingLeft: 4}}>
                <input
                  className={classes.searchipt}
                  type="text"
                  id="keyword"
                  onChange={(e) => setSearchKey(e.target.value)}
                  value={searchKey}
                  name="keyword"></input>
                <Button
                  className={classes.searchbtn}
                  onClick={() => {
                    searchFilter();
                    postTopAd();
                  }}>
                  {t('Search')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {boardId === 1 && (
        <div className={classes.topad}>
          <div className={classes.topPage}>
            <div className={classes.topAd}>
              <Topadinfo />
            </div>
            {loading ? (
              <div style={{textAlign: 'center'}}>
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              </div>
            ) : found.length > 0 ? (
              <div>
                {_.map(found, (top) => {
                  const boardSido = _.find(sidoList, function (o) {
                    return `${o.code}` === top.sido_code;
                  })?.name;

                  const sigungu = _.find(sidoList, function (o) {
                    return `${o.code}` === top.sido_code;
                  })?.sigungus;

                  const boardSigg = _.find(sigungu, function (o) {
                    return `${o.code}` === top.sgg_code;
                  })?.name;

                  const boardCat = _.find(postList, function (o) {
                    return o.id === top.id;
                  })?.cat1;

                  const postTitle = _.find(postList, function (o) {
                    return o.title === top.title;
                  });

                  let title = postTitle?.title;
                  if (lang === 'ch' && postTitle?.title_ch) {
                    title = postTitle?.title_ch;
                  }
                  if (lang === 'ru' && postTitle?.title_ru) {
                    title = postTitle?.title_ru;
                  }
                  if (lang === 'vi' && postTitle?.title_vi) {
                    title = postTitle?.title_vi;
                  }

                  return (
                    <div className={classes.topAdDiv}>
                      <p className={classes.topAdTitle} style={{minWidth: 40}}>
                        {moment(top.updated_at).format('MM-DD')}
                      </p>
                      <p
                        className={clsx(classes.topAdTitle, classes.postTitle)}
                        onClick={() => {
                          history.push(`${top?.id}`);
                        }}>
                        {title}
                      </p>
                      <div className={clsx(classes.topAdSido, classes.catDiv)}>
                        {boardCat ? (
                          <>
                            ({t(`${boardSido}`)} {t(`${boardSigg}`)}) -{' '}
                            {t(`${boardCat}`)}
                          </>
                        ) : (
                          <>
                            ({t(`${boardSido}`)} {t(`${boardSigg}`)})
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{justifyContent: 'center', color: 'gray'}}
                className={classes.nonTopAdDiv}>
                {t('게시글이 존재하지 않습니다.')}
              </div>
            )}
          </div>
        </div>
      )}

      <div className={classes.bottomad}>
        <div className={classes.bottomadpr}>
          {loading ? (
            <div style={{textAlign: 'center'}}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </div>
          ) : postFound.length > 0 ? (
            postFound && renderData(postFound)
          ) : (
            <div
              style={{justifyContent: 'center', color: 'gray'}}
              className={classes.nonPostDiv}>
              {t('게시글이 존재하지 않습니다.')}
            </div>
          )}
        </div>
      </div>
      <div className={classes.pageUl}>
        <ul className={classes.paging}>
          <li>
            <button
              className={classes.pagingPrev}
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}>
              <ArrowBackIosIcon style={{fontSize: '14px', color: '#979696'}} />
            </button>
          </li>

          <div className="pageNum" style={{display: 'flex'}}>
            {renderPageNumbers}
          </div>

          <li>
            <button
              className={classes.pagingNext}
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}>
              <ArrowForwardIosIcon
                style={{fontSize: '14px', color: '#979696'}}
              />
            </button>
          </li>
        </ul>
        <div className={classes.onlyPcbtn}>
          <Button onClick={handleTop} className={classes.topBtnMobile}>
            <img src={TopIcon} alt="topIcon" style={{marginRight: '5px'}} />
            {t('맨위로')}
          </Button>
        </div>
      </div>
      <div className={classes.pageMobile}>
        <div></div>
        <div style={{textAlign: 'center', marginBottom: '6px'}}>
          <input
            className={classes.searchInputMobile}
            type="text"
            id="title_txt"
            name="title"
            onChange={(e) => setSearchKey(e.target.value)}
            value={searchKey}
          />
          <Button
            className={classes.searchBtnMobile}
            onClick={() => {
              searchFilter();
              postTopAd();
            }}>
            {t('Search')}
          </Button>
        </div>
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleTop} className={classes.onlyMobileButton}>
            <img src={TopIcon} alt="topIcon" style={{marginRight: '5px'}} />
            {t('맨위로')}
          </Button>
        </div>
      </div>
      <Footer />
      <div style={{display: 'none'}}>
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
    </div>
  );
};

export default Employ;
