import React from 'react';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {MobileWeb} from './Mobile/Breakpoints';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@material-ui/core';
import logo from '../image/winner_logo.png';
import {Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  // Footer
  footer: {
    margin: '0 auto',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      justifyContent: 'center',
    },
  },

  logofooter: {
    fontFamily: 'Times New Roman, Times serif',
    fontSize: '30px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    marginRight: '30px',
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: '0px',
      fontSize: '11px',
    },
  },

  logoTitle: {
    fontFamily: 'Times New Roman, Times serif',
    fontSize: '25px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    margin: '0',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: '0px',
      fontSize: '8px',
      padding: '0',
    },
  },

  logoName: {
    color: '#FF3900',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      color: '#333',
      alignSelf: 'center',
    },
  },

  footbar: {
    width: '100%',
    bottom: '0%',
    background: '#daebf0',
    borderTop: '1px solid #ccc',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#daebf0',
      height: '80px',
      width: '100%',
      padding: '0',
      borderTop: 'none',
      position: 'fixed',
      textAlign: 'center',
      gap: 12,
    },
  },

  footbarul: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 980,
    width: '100%',
    paddingLeft: 50,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      textAlign: 'center',
      justifyContent: 'center',
      paddingLeft: 0,
    },
  },

  footbarli: {
    display: 'inline',
    padding: '12px 30px 6px 0',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 10px 0px',
    },
  },

  footerside: {
    font: 'Noto Sans KR, sans-serif',
    fontSize: '12px',
    textDecoration: 'none',
    color: '#333',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      font: 'Noto Sans KR, sans-serif',
      fontSize: '11px',
      textDecoration: 'none',
      color: '#333',
    },
  },

  footbarliMobile: {
    display: 'none',
    padding: '12px 30px 6px 0',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 6px 0px',
      display: 'inline',
    },
  },

  footbarliPC: {
    padding: '12px 30px 6px 0',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 10px 0px',
      display: 'none',
    },
  },

  totalDiv: {
    fontWeight: 600,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      alignSelf: 'center',
    },
  },

  onlyPC: {
    paddingLeft: 50,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  logoImg: {
    width: 80,
    height: 60,
    marginLeft: 20,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: 60,
      height: 45,
      marginLeft: 4,
    },
  },
  // Footer - END

  //
}));

const Footer = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(426));
  const tab = useMediaQuery(theme.breakpoints.down(MobileWeb.mobile));

  if (!tab) {
    return (
      <div className={classes.footer}>
        <div className={classes.footbar}>
          {!tab && (
            <div>
              <img src={logo} alt="logo" className={classes.logoImg} />
            </div>
          )}
          <div className={classes.totalDiv}>
            <ul className={classes.footbarul}>
              <li className={classes.footbarliPC}>
                <Link to="/" className={classes.logofooter}>
                  8282
                  <p className={classes.logoTitle}>{t('빨리빨리')} </p>
                  9297
                  <p className={classes.logoTitle}>{t('구인구직_')} Friend</p>
                </Link>
              </li>
              <li className={classes.footbarliMobile}>
                <Link to="/" className={classes.logofooter}>
                  8282{!mobile && t('빨리빨리')} 9297
                  {mobile ? (
                    <p className={classes.logoTitle} style={{marginLeft: 5}}>
                      {' '}
                      Friend
                    </p>
                  ) : (
                    <p className={classes.logoTitle}>{t('구인구직_')} Friend</p>
                  )}
                </Link>
              </li>
            </ul>
            <div className={classes.onlyPC}>
              <div
                style={{
                  fontSize: '9px',
                  display: 'flex',
                  fontWeight: 500,
                  color: '#aaa',
                }}>
                <div style={{paddingRight: '10px'}}>
                  <Typography fontSize={9}>
                    {t('위너커머스 주식회사')}
                  </Typography>
                  <Typography fontSize={9}>
                    {t('이메일')} :{' '}
                    <a href="mailto:winnercm@naver.com">winnercm@naver.com</a>
                  </Typography>
                </div>
                <div style={{paddingRight: '10px'}}>
                  <Typography fontSize={9}>
                    {t('사업자등록증')} : 310-81-34901
                  </Typography>
                  <Typography fontSize={9}>
                    {t('직업정보제공사업')} : J1500020230004
                  </Typography>
                </div>
                <div style={{paddingRight: '10px'}}>
                  <Typography fontSize={9}>
                    {t('통신판매업')} :{t('2023-인천연수구-0712 호')}
                  </Typography>
                  <Typography fontSize={9}>
                    {t('인천광역시 연수구 송도미래로_2')}
                  </Typography>
                </div>
                <div style={{paddingRight: '10px'}}>
                  <Typography fontSize={9}>
                    032-813-7787 / 070-4277-7788
                  </Typography>
                  <Typography
                    style={{color: 'red'}}
                    fontSize={9}
                    fontWeight={'bold'}>
                    {t('카카오채널')} : 114114{t('구인구직')}
                    {t('채널추가')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer - END */}
      </div>
    );
  } else {
    return (
      <div className={classes.footer}>
        <div className={classes.footbar}>
          <div>
            <img src={logo} alt="logo" className={classes.logoImg} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}>
            <div
              style={{
                display: 'flex',
                gap: 4,
              }}>
              <Typography fontSize={6}>{t('위너커머스 주식회사')}</Typography>
              <Typography fontSize={6}>
                {t('인천광역시 연수구 송도미래로_2')}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                gap: 4,
              }}>
              <Typography fontSize={6}>
                {t('사업자등록증')} : 310-81-34901
              </Typography>
              <Typography fontSize={6}>
                {t('직업정보제공사업')} : J1500020230004
              </Typography>
              <Typography fontSize={6}>
                {t('통신판매업')} :{t('2023-인천연수구-0712 호')}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                gap: 4,
              }}>
              <Typography fontSize={6}>032-813-7787 / 070-4277-7788</Typography>
              <Typography fontSize={6}>
                {t('이메일')} :{' '}
                <a href="mailto:winnercm@naver.com">winnercm@naver.com</a>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Footer;
