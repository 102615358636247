import React, {useCallback, useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {MobileWeb} from './Breakpoints';
import MenuIcon from '@material-ui/icons/Menu';
import {useHistory} from 'react-router-dom';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import NewitemModal from '../Newitemmodal';
import {VERIFY} from '~/features/Verify/slice';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {ListItemIcon} from '@material-ui/core';
import {KoreaIcon} from '~/image/KoreaIcon';
import {RussiaIcon} from '~/image/RussiaIcon';
import {ChinaIcon} from '~/image/ChinaIcon';
import {VietnamIcon} from '~/image/VietnamIcon';
import './MobileHeadercss.css';
import {useTranslation} from 'react-i18next';
import {LangCode, setLang} from '~/features/Login/slice';

const useStyles = makeStyles((theme) => ({
  menuBtn: {
    left: '163px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menuList: {
    fontSize: '12px!important',
    padding: '8px 20px!important',
    margin: '0!important',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none!important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menuMain: {
    fontSize: '12px!important',
    padding: '0px!important',
    margin: '0!important',
    border: '0!important',
    minHeight: '0!important',
    backgroundColor: '#daebf0',
    boxShadow: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menuDetails: {
    padding: '0px!important',
    margin: '0px!important',
    minHeight: '0!important',
    backgroundColor: '#daebf0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menuPaper: {
    width: '115px',
    // height: '189px',
    textAlignLast: 'left',
  },

  typography: {
    padding: '8px 20px',
    fontSize: '12px!important',
  },

  test: {
    width: '115px',
  },

  list: {
    width: '180px',
    backgroundColor: '#daebf0',
  },
  fullList: {
    width: 'auto',
  },

  accodianContent: {
    margin: '0',
  },

  accodionRoot: {
    position: 'static',
  },
}));

interface HeaderProps {
  langProp?: any;
}

export default function MobileHeader(props: HeaderProps) {
  const classes = useStyles();
  const history = useHistory();
  const [valueCountry, setValueCountry] = useState('');
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {langProp} = props;

  const [openModal, setOpenModal] = useState(false);
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const myitem = () => {
    history.push('/myitem');
  };
  const write = () => {
    history.push('/board');
  };
  // 번역

  const changeLang = useCallback(
    (lang: LangCode) => {
      dispatch(setLang(lang));
      langProp(lang);
    },
    [dispatch, langProp],
  );

  ///

  type Anchor = 'top' | 'left' | 'bottom' | 'right';

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({...state, [anchor]: open});
    };

  const changeCountry = () => {
    if (valueCountry === 'ko') {
      return (
        <>
          <ListItemIcon style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
            <KoreaIcon />
          </ListItemIcon>
          한국어
        </>
      );
    } else if (valueCountry === 'ch') {
      return (
        <>
          <ListItemIcon style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
            <ChinaIcon />
          </ListItemIcon>
          中國語
        </>
      );
    } else if (valueCountry === 'ru') {
      return (
        <>
          <ListItemIcon style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
            <RussiaIcon />
          </ListItemIcon>
          Russian
        </>
      );
    } else if (valueCountry === 'vi') {
      return (
        <>
          <ListItemIcon style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
            <VietnamIcon />
          </ListItemIcon>
          Vitenam
        </>
      );
    } else if (valueCountry === '') {
      return <div style={{margin: '0'}}>{t('언어선택')}</div>;
    }
  };

  const [accoValue, setAccoValue] = useState(false);
  const list = (anchor: Anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List style={{padding: '0'}}>
        <div
          className={classes.typography}
          onClick={() => {
            history.push('/');
          }}>
          {t('홈으로')}
        </div>
        <div
          className={classes.typography}
          onClick={() => {
            user ? write() : setOpenModal(true);
          }}>
          {t('정보등록')}
        </div>
        <div
          className={classes.typography}
          onClick={() => {
            user ? myitem() : setOpenModal(true);
          }}>
          {t('내가 쓴 글')}
        </div>
        <Accordion
          elevation={0}
          expanded={accoValue}
          onClick={() => {
            setState({top: true, left: true, bottom: true, right: true});
            // setAccoValue(true);
          }}
          classes={{
            expanded: classes.menuMain,
            root: classes.accodionRoot,
          }}>
          <AccordionSummary
            onClick={() => {
              accoValue === true ? setAccoValue(false) : setAccoValue(true);
            }}
            classes={{
              expanded: classes.menuDetails,
              root: classes.menuMain,
              content: classes.accodianContent,
            }}>
            <div className={classes.menuList}>{changeCountry()}</div>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.menuDetails}}>
            <div>
              <div
                className={classes.menuList}
                onClick={() => {
                  setValueCountry('ko');
                  setAccoValue(false);
                  changeLang('ko');
                }}>
                <ListItemIcon
                  style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
                  <KoreaIcon />
                </ListItemIcon>
                한국어
              </div>
              <div
                className={classes.menuList}
                onClick={() => {
                  setValueCountry('ch');
                  setAccoValue(false);
                  changeLang('ch');
                }}>
                <ListItemIcon
                  style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
                  <ChinaIcon />
                </ListItemIcon>
                中國語
              </div>
              <div
                className={classes.menuList}
                onClick={() => {
                  setValueCountry('ru');
                  setAccoValue(false);
                  changeLang('ru');
                }}>
                <ListItemIcon
                  style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
                  <RussiaIcon />
                </ListItemIcon>
                Russian
              </div>
              <div
                className={classes.menuList}
                onClick={() => {
                  setValueCountry('vi');
                  setAccoValue(false);
                  changeLang('vi');
                }}>
                <ListItemIcon
                  style={{minWidth: '35px', backgroundColor: '#daebf0'}}>
                  <VietnamIcon />
                </ListItemIcon>
                Vitenam
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </List>
    </div>
  );

  ///
  return (
    <div className={classes.menuBtn}>
      <Button
        onClick={() => {
          setOpen(true);
        }}>
        {<MenuIcon fontSize="small" />}
      </Button>
      <Drawer
        classes={{paper: classes.list}}
        anchor={'right'}
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        {list('right')}
      </Drawer>

      <div style={{display: 'none'}}>
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
    </div>
  );
}
