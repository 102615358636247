import React, {useCallback, useEffect, useState} from 'react';
import {Theme, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import './Maincss.css';
import Fab from '@material-ui/core/Fab';
import {useTranslation} from 'react-i18next';
import ChinaIcon from '../image/china.png';
import KoreaIcon from '../image/korea.png';
import RussiaIcon from '../image/russia.png';
import VietnamIcon from '../image/vietnam.png';
import Footer from '~/components/Footer';
import {MobileWeb} from '../components/Mobile/Breakpoints';
import Header from '~/components/Header';
import {LangCode, setLang} from '~/features/Login/slice';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  body: {
    // display: 'flex',
    justifyContent: 'center',
    margin: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  page: {
    maxWidth: 'fit-content',
    margin: '0 auto 110px',
    padding: '72px 0 0 ',
    color: '#333',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '100%',
      padding: '46px 0 0',
      display: 'block',
      minWidth: 360,
    },
  },

  logo: {
    fontSize: '60px',
    lineHeight: 1.2,
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: 'Montaga',
    margin: 0,
    padding: '0 0 7px',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '50px',
      display: 'revert',
    },
  },

  mobileLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 12,
  },

  root: {
    alignSelf: 'center',
    display: 'flex',
    // justifyContent: 'space-around',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  extendedIcon: {
    margin: '50px 30px',
    display: 'block',
    textAlign: 'center',
    width: '75px',
    height: '75px',
    boxShadow: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '12px',
      width: '48px',
      height: '48px',
    },
  },

  country: {
    fontFamily: 'Noto Sans Bengali',
    fontSize: '14px',
    lineHeight: '18.55px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      lineHeight: '14px',
      margin: '0',
    },
  },

  titleFont: {
    fontFamily: 'Montaga',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  logoPr: {
    textAlign: 'center',
    padding: '0 0 7px',
    fontSize: '20px',
    fontFamily: 'Noto Sans',
    height: '39px',
    fontWeight: 600,
    width: '100%',
    // maxWidth: 980,
    minWidth: 360,
    textDecoration: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '15px',
      height: '100%',
      padding: 'auto',
    },
  },

  logoTitle: {
    fontSize: '45px',
    lineHeight: 1.2,
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: 'Montaga',
    margin: '0 0 0 14px',
    padding: '0',
    alignSelf: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '24px',
    },
  },

  mobileLi: {
    width: 75,
    alignSelf: 'center',
    paddingRight: 5,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      borderBottom: '1px solid #E0E0E0',
      padding: 0,
      width: '100%',
      minWidth: 360,
    },
  },

  menu: {
    background: '#daebf0',
    // background: '#d0efff',
    border: '1px solid #ccc',
    padding: '28px 39px 12px 38px',
    fontSize: '14px',
    minWidth: 800,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: 'none',
      border: 'none',
      padding: '0',
      // marginBottom: '56px',
      minWidth: 360,
    },
  },

  tit: {
    fontWeight: 'bold',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#daebf0',
      // border: '1px solid #ccc',
      fontSize: '12px',
      fontWeight: 'bold',
      padding: '3.5px 16px',
    },
  },

  menulist: {
    marginTop: 14,
    marginBottom: 0,
    padding: '0',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '0',
      display: 'block',
    },
  },

  menulistLastChild: {
    margin: '14px 0px 0px 0px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menulistLiA: {
    color: '#333',
    textDecorationLine: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  menulistLink: {
    color: '#333',
    textDecorationLine: 'none',
    cursor: 'pointer',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      textDecorationLine: 'none',
      color: '#000',
      fontSize: '12px',
      margin: '7px 15.4px 4px',
    },
  },

  header: {
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  divFlex: {
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      display: 'flex',
      width: 800,
      justifyContent: 'space-between',
      paddingBottom: 7,
    },
  },

  bottomDiv: {
    marginTop: 38,
    padding: '0 10px',
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      alignSelf: 'center',
      marginTop: 50,
    },
  },

  mobileBottomDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  bottomTag: {
    textAlign: 'center',
    fontSize: 15,
    minWidth: 250,
    border: '1px solid #ccc',
    background: '#eee',
    padding: '14px 25px',
    marginBottom: 15,
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      fontSize: 30,
      marginBottom: 20,
      cursor: 'pointer',
      maxWidth: 560,
      width: '100%',
    },
  },

  logoImg: {
    width: 80,
    height: 60,
    marginLeft: 20,
    [theme.breakpoints.up(MobileWeb.mobile)]: {},
  },
}));

const Mainpage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(557));

  // const theme = useTheme() as Theme;
  // const mobile = useMediaQuery(theme.breakpoints.down(683));

  const changeLang = useCallback(
    (lang: LangCode) => {
      dispatch(setLang(lang));
    },
    [dispatch],
  );

  // 번역

  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  return (
    <div className={classes.body}>
      <div className={classes.header}>
        <Header
          langProp={(headerLang: LangCode) => {
            setHeaderLang(headerLang);
          }}
        />
      </div>
      <div className={classes.page}>
        {!mobile && (
          <h1 className={classes.logo}>
            <div>8282</div>
            <p className={classes.logoTitle}>{t('빨리빨리')}</p>
            <div style={{marginLeft: 14}}>9297</div>
            <p className={classes.logoTitle}>{t('구인구직_')} Friend</p>
          </h1>
        )}
        {mobile && (
          <h1 className={classes.logo}>
            <div className={classes.mobileLogo}>
              <div>8282</div>
              <div style={{marginLeft: 14}}>9297</div>
            </div>

            <div className={classes.mobileLogo}>
              <p className={classes.logoTitle}>{t('빨리빨리')}</p>
              <p className={classes.logoTitle}>{t('구인구직_')}</p>
            </div>

            <div>Friend</div>
          </h1>
        )}

        {mobile && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 28,
            }}>
            <Fab
              className={classes.extendedIcon}
              onClick={() => {
                changeLang('ko');
                setHeaderLang('ko');
              }}>
              <img alt="Korea" width={48} height={48} src={KoreaIcon} />
              <p className={classes.country}>한국어</p>
            </Fab>
            <Fab
              className={classes.extendedIcon}
              onClick={() => {
                changeLang('ch');
                setHeaderLang('ch');
              }}>
              <img alt="Chian" width={48} height={48} src={ChinaIcon} />
              <p className={classes.country}>中國語</p>
            </Fab>
            <Fab
              className={classes.extendedIcon}
              onClick={() => {
                changeLang('ru');
                setHeaderLang('ru');
              }}>
              <img alt="Russia" width={48} height={48} src={RussiaIcon} />
              <p className={classes.country}>Russian</p>
            </Fab>
            <Fab
              className={classes.extendedIcon}
              onClick={() => {
                changeLang('vi');
                setHeaderLang('vi');
              }}>
              <img alt="Vietnam" width={48} height={48} src={VietnamIcon} />
              <p className={classes.country}>Vietnam</p>
            </Fab>
          </div>
        )}

        <div className={classes.logoPr}>{t('정규직_')}</div>
        <a
          href="http://pf.kakao.com/_dRDxmG"
          className={classes.logoPr}
          style={{color: 'red'}}>
          {t('카카오채널')} : 114114{t('구인구직')} {`(문의 접수)`}
        </a>
        <div className={classes.menu}>
          <div className={classes.tit}>{t('일자리(JOB) 선택(Choice)')}</div>
          <ul className={classes.menulist}>
            <div>
              <div className={classes.divFlex}>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=11');
                    }}
                    className={classes.menulistLink}>
                    {t('서울특별시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=31');
                    }}
                    className={classes.menulistLink}>
                    {t('경기도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=23');
                    }}
                    className={classes.menulistLink}>
                    {t('인천광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=32');
                    }}
                    className={classes.menulistLink}>
                    {t('강원도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=33');
                    }}
                    className={classes.menulistLink}>
                    {t('충청북도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=34');
                    }}
                    className={classes.menulistLink}>
                    {t('충청남도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=29');
                    }}
                    className={classes.menulistLink}>
                    {t('세종특별시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=35');
                    }}
                    className={classes.menulistLink}>
                    {t('전라북도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=36');
                    }}
                    className={classes.menulistLink}>
                    {t('전라남도')}
                  </div>
                </li>
              </div>
              <div className={classes.divFlex}>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=24');
                    }}
                    className={classes.menulistLink}>
                    {t('광주광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=37');
                    }}
                    className={classes.menulistLink}>
                    {t('경상북도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=38');
                    }}
                    className={classes.menulistLink}>
                    {t('경상남도')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=26');
                    }}
                    className={classes.menulistLink}>
                    {t('울산광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=22');
                    }}
                    className={classes.menulistLink}>
                    {t('대구광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=25');
                    }}
                    className={classes.menulistLink}>
                    {t('대전광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=21');
                    }}
                    className={classes.menulistLink}>
                    {t('부산광역시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1&sido=39');
                    }}
                    className={classes.menulistLink}>
                    {t('제주특별시')}
                  </div>
                </li>
                <li className={classes.mobileLi}>
                  <div
                    onClick={() => {
                      history.push('/info?boardId=1&page=1');
                    }}
                    className={classes.menulistLink}>
                    {t('TOTAL')}
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </div>
        <div className={classes.root}>
          <Fab
            className={classes.extendedIcon}
            onClick={() => {
              changeLang('ko');
              setHeaderLang('ko');
            }}>
            <img alt="Korea" src={KoreaIcon} />
            <p className={classes.country}>한국어</p>
          </Fab>
          <Fab
            className={classes.extendedIcon}
            onClick={() => {
              changeLang('ch');
              setHeaderLang('ch');
            }}>
            <img alt="Chian" src={ChinaIcon} />
            <p className={classes.country}>中國語</p>
          </Fab>
          <Fab
            className={classes.extendedIcon}
            onClick={() => {
              changeLang('ru');
              setHeaderLang('ru');
            }}>
            <img alt="Russia" src={RussiaIcon} />
            <p className={classes.country}>Russian</p>
          </Fab>
          <Fab
            className={classes.extendedIcon}
            onClick={() => {
              changeLang('vi');
              setHeaderLang('vi');
            }}>
            <img alt="Vietnam" src={VietnamIcon} />
            <p className={classes.country}>Vietnam</p>
          </Fab>
        </div>
        {/* 임시 제거 */}
        <div className={classes.bottomDiv}>
          {/* {mobile ? (
            <div className={classes.mobileBottomDiv}>
              <Typography fontSize={'30px'}>{t('NEW 오픈(OPEN)')}</Typography>
              <Typography fontSize={'30px'}>
                {t('NEW 단장(Renewal)')} {t('기념')}
              </Typography>
              <Typography textAlign={'center'} fontSize={'30px'}>
                {t('지금 접수시_')}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography fontSize={'2rem'}>
                {t('NEW 오픈(OPEN)')} {t('NEW 단장(Renewal)')} {t('기념')}
              </Typography>
              <Typography fontSize={'2rem'}>{t('지금 접수시_')}</Typography>
            </div>
          )} */}
          {/* {headerLang === 'ch' ? (
            mobile ? (
              <div>
                <Typography fontSize={'30px'} color={'red'}>
                  纪念新开业新装!!
                </Typography>

                <Typography fontSize={'30px'} color={'red'}>
                  !! 现在受理时免费提供10日收费公告 !!
                </Typography>
              </div>
            ) : (
              <div>
                <Typography fontSize={'2rem'} color={'red'}>
                  纪念新开业新装!!
                </Typography>

                <Typography fontSize={'2rem'} color={'red'}>
                  !! 现在受理时免费提供10日收费公告 !!
                </Typography>
              </div>
            )
          ) : mobile ? (
            <div className={classes.mobileBottomDiv}>
              <Typography fontSize={'30px'}>{t('NEW 오픈(OPEN)')}</Typography>
              <Typography fontSize={'30px'}>
                {t('NEW 단장(Renewal)')} {t('기념')}
              </Typography>
              <Typography textAlign={'center'} fontSize={'30px'}>
                {t('지금 접수시_')}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography fontSize={'2rem'}>
                {t('NEW 오픈(OPEN)')} {t('NEW 단장(Renewal)')} {t('기념')}
              </Typography>
              <Typography fontSize={'2rem'}>{t('지금 접수시_')}</Typography>
            </div>
          )} */}
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer - END */}
    </div>
  );
};

export default Mainpage;
