import {
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import {useTranslation} from 'react-i18next';
import {LangCode, setLang} from '~/features/Login/slice';

const useStyles = makeStyles((theme) => ({
  layout: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
    },
  },

  fnqDiv: {
    fontSize: theme.typography.pxToRem(15),
    marginTop: 40,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0 15px',
    },
  },

  fnqHeader: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
    marginBottom: 30,
  },

  fnqContent: {},

  contentF: {
    display: 'flex',
    borderBottom: '0.5px solid #eee',
    height: 45,
    alignItems: 'center',
    '&:first-child': {
      borderTop: '0.5px solid #eee',
    },
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },
  contentN: {
    marginRight: 8,
  },

  fnqBottom: {
    padding: '10px 18px',
    borderBottom: '0.5px solid #eee',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px',
    },
  },
  //
}));

const About = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(MobileWeb.mobile));

  // 번역

  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  return (
    <div>
      <Header
        langProp={(headerLang: LangCode) => {
          setHeaderLang(headerLang);
        }}
      />

      <div className={classes.layout}>
        <div className={classes.fnqDiv}>
          <div className={classes.fnqHeader}>
            <div>
              <Typography variant="h4">{t('사이트 소개')}</Typography>
            </div>
          </div>
          <div className={classes.fnqContent}>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('1141149297 friend')}
              </Typography>
            </div>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('누구나 간단하게 글을_')}
              </Typography>
            </div>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('불필요한 소스들을 제거하여_')}
              </Typography>
            </div>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('구인구직,중고시장,부동산 정보_')}
              </Typography>
            </div>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('동포분들의 편의를 위하여_')}
              </Typography>
            </div>
            <div className={classes.contentF}>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('이용자분들의 만족을 위하여_')}
              </Typography>
            </div>
          </div>
          <div className={classes.fnqBottom}>
            <div>
              <Typography variant={mobile ? 'caption' : 'body1'}>
                {t('감사합니다.')}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
