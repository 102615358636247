import React, {useEffect, useState, useMemo} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import _ from 'lodash';
import {Form} from 'antd';
import {API_URI} from '~/api/apiServer';
import {useDispatch, useSelector} from 'react-redux';
import {createBoard, BOARD} from '~/features/Board/slice';
import {RootState} from '~/app/rootReducers';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dlborder: {
    borderBottom: '1px solid #ccc',
    paddingBottom: '15px',
    marginBottom: '15px',
    display: 'block',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectinput: {
    margin: '0 15px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  spanStyle: {
    marginRight: 15,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '0px',
      fontSize: '11px',
    },
  },
}));

interface ChildProps {
  LoadId?: any;
  LoadName?: any;
}

const BoardRadio = (props: ChildProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(createBoard({id: 1}));
  }, [dispatch]);

  const [board, setBoard] = useState('1');

  const {boardList} = useSelector((state: RootState) => state[BOARD]) ?? {};

  const boardId = useMemo(() => {
    return _.find(boardList, function (o) {
      return `${o.id}` === board;
    })?.id;
  }, [boardList, board]);

  const boardName = useMemo(() => {
    return _.find(boardList, function (o) {
      return `${o.name}` === board;
    })?.name;
  }, [boardList, board]);

  const classes = useStyles();
  useEffect(() => {
    const handleInputItem = async () => {
      try {
        await axios
          .get(`${API_URI}/boards?id=${boardId}`)
          .then(() => {})
          .catch(() => {});
      } catch (error) {}
    };
    handleInputItem();
  }, [board, boardId]);

  useEffect(() => {
    const handleInputItem = async () => {
      try {
        await axios
          .get(`${API_URI}/boards?id=${boardId}`)
          .then(() => {})
          .catch(() => {});
      } catch (error) {}
    };
    handleInputItem();
  }, [boardId]);

  useEffect(() => {
    setBoard(board);
  }, [board]);

  const handleInputItem = async () => {
    try {
      await axios
        .get(`${API_URI}/boards?id=${boardId}`)
        .then(() => {})
        .catch(() => {});
    } catch (error) {}
  };
  return (
    <div className={classes.dlborder}>
      <div style={{margin: '41px 20px 8px 0px'}}>{t('글쓰기')}</div>
      <Form>
        <span className={classes.spanStyle}>{t('게시판')} : </span>
        <input
          type="radio"
          id="collect"
          name="newitem"
          value="1"
          onChange={(e) => {
            setBoard(e.target.value);
          }}
          onClick={() => {
            handleInputItem();
            props.LoadId(1);
            props.LoadName(boardName);
          }}
          // checked={board === '1'}
        />
        <label htmlFor="채용정보" className={classes.selectinput}>
          {t('채용정보')}
        </label>
        <input
          type="radio"
          id="collect"
          name="newitem"
          value="2"
          onChange={(e) => {
            setBoard(e.target.value);
          }}
          onClick={() => {
            handleInputItem();
            props.LoadId(2);
            props.LoadName(boardName);
          }}
          // checked={board === '2'}
        />
        <label htmlFor="인재공고" className={classes.selectinput}>
          {t('인재공고')}
        </label>
        <input
          type="radio"
          id="collect"
          name="newitem"
          value="3"
          onChange={(e) => {
            setBoard(e.target.value);
          }}
          onClick={() => {
            handleInputItem();
            props.LoadId(3);
            props.LoadName(boardName);
          }}
          // checked={board === '3'}
        />
        <label htmlFor="중고시장" className={classes.selectinput}>
          {t('단체모임방')}
        </label>
        <input
          type="radio"
          id="collect"
          name="newitem"
          value="4"
          onChange={(e) => {
            setBoard(e.target.value);
          }}
          onClick={() => {
            handleInputItem();
            props.LoadId(4);
            props.LoadName(boardName);
          }}
          // checked={board === '4'}
        />
        <label htmlFor="부동산정보" className={classes.selectinput}>
          {t('일자리 후기')}
        </label>
      </Form>
    </div>
  );
};
export default BoardRadio;
