import {
  Button,
  CircularProgress,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import _ from 'lodash';
import {LOGIN, setLang, LangCode} from '~/features/Login/slice';
import {useTranslation} from 'react-i18next';
import {ITEMS_PER_PAGE} from '~/contants/Pagination';
import {loadPost} from '~/features/Post/slice';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {createBoardId, IBoard} from '~/features/Board/slice';
import moment from 'moment';
import Header from '~/components/Header';
import {Tabs} from '@material-ui/core';
import Tab from '@mui/material/Tab';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TopIcon from '~/image/Top.png';
import Footer from '~/components/Footer';
import usePagination from '~/hooks/usePagination';

const useStyles = makeStyles((theme) => ({
  // Middle
  listul: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto ',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 360,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0',
      margin: '0 auto',
      background: '#FFF',
      justifyContent: 'center',
    },
  },

  pageUl: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto 130px',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      padding: '0',
      margin: '0 auto',
      background: '#FFF',
      justifyContent: 'center',
    },
  },

  listli: {
    padding: '16px, 0 0 0',
    // float: 'left',
    listStyle: 'none',
    '.MuiTabs-indicator': {
      display: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  listlink: {
    padding: '0px 15px !important',
    fontSize: '12px!important',
    color: '#333!important',
    fontFamily: 'Gulim, Tahoma, Arial, Verdana, Helvetica, sans-serif',
    float: 'left',
    opacity: '1!important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 12px!important',
      fontWeight: '600!important',
    },
  },

  topad: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',
    padding: '0px 15px 15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      padding: '0',
      margin: '0 auto',
    },
  },

  bottomad: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
    },
  },

  bottomadpr: {
    borderTop: '0.5px solid #979696',
    borderBottom: '0.5px solid #979696',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '11px 15px 4px',
      borderBottom: '0',
      marginBottom: 20,
    },
  },

  mobileFixed: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      top: '0',
    },
  },

  test: {
    display: 'none',
  },

  selected: {
    color: '#ff3900 !important',
  },

  topPage: {
    width: '100%',
    maxWidth: 980,
    left: '230px',
    background: '#fffcf9',
    borderTop: '0.5px solid #eee',
    borderBottom: '0.5px solid #eee',
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '13px',
    margin: '0 auto',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      borderTop: '0',
      borderBottom: '0',
      padding: '18px 0 10px 5px',
    },
  },

  searchform: {
    display: 'flex',
    marginRight: 12,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  searchipt: {
    width: '109px',
    height: '20px',
    lineHeight: '23px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '0',
    float: 'left',
    margin: '0 -1px 0 0',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  searchbtn: {
    minWidth: '50px',
    height: '22px',
    float: 'left',
    border: '1px solid #ccc',
    background: '#ddd',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '11px',

    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  pageMobile: {
    width: '100%',
    maxWidth: 980,
    margin: '0 auto',
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: 572,
      minWidth: 360,
      margin: '0 auto',
      fontSize: '13px',
      display: 'inline',
    },
  },

  topBtnMobile: {
    border: '1px solid: #979696',
    background: '#F0EFEF',
    color: '#979696',
    borderRadius: 0,
    minWidth: '75px',
    height: '24px',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  onlyMobileButton: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      width: 'auto',
      height: '30px',
      border: '1px solid: #E5E5E5',
      background: '#F0EFEF',
      color: '#979696',
      borderRadius: 0,
      fontSize: '11px',
      margin: '6px 0 52px',
    },
  },

  searchInputMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      width: '271px',
      height: '26px',
      border: '1px solid #E0E0E0',
    },
  },

  searchBtnMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      minWidth: '57px',
      height: '30px',
      fontSize: '11px',
      border: '1px solid #E0E0E0',
      background: '#F0EFEF',
    },
  },

  topAdDiv: {
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      height: '100%',
      padding: '0 10px',
    },
  },

  paging: {
    margin: '10px 3.5px 120px 0px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '15px',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '10px 3.5px 11.5px 0px',
    },
  },

  pagingPrev: {
    marginRight: '3.5px',
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  pagingNext: {
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  onlyPcbtn: {
    display: 'flex',
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectlist: {
    display: 'flex',
    alignSelf: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginLeft: '12px',
    },
  },

  postDiv: {
    display: 'flex',
    cursor: 'pointer',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '15px',
      fontSize: '12px',
    },
  },

  nonPostDiv: {
    display: 'flex',
    height: '20px',
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '15px',
      fontSize: '12px',
    },
  },

  buttonProgress: {
    color: 'gray',
  },

  notice: {
    color: '#ff3900',
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 'bold',
    [theme.breakpoints.up(MobileWeb.mobile)]: {},
  },

  postTitle: {
    paddingRight: 10,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      overflow: 'hidden',
      minWidth: 155,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  //
}));

interface IState {
  mode?: number;
}

const Community = () => {
  const location = useLocation();
  const mode = (location.state as IState)?.mode;
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(MobileWeb.mobile));
  const {t} = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {lang} = useSelector((root: RootState) => root[LOGIN]);

  const [boardId, setBoardId] = useState<number>(3);
  const [value, setValue] = React.useState(mode === 3 ? 2 : 3);
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitPost, setLimitPost] = useState(0);
  const [headerLang, setHeaderLang] = useState<LangCode>();
  const [testList, setTestList] = useState<IBoard[]>();

  // PagiNation

  const [currentPage, setcurrentPage] = useState(1);
  const pageNumberLimit = Math.ceil(limitPost / ITEMS_PER_PAGE);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  // 번역

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  // 최대 게시글

  useEffect(() => {
    const maxPosts = async () => {
      try {
        const resp = await axios.get(`${API_URI}/posts/count?board=${boardId}`);
        setLimitPost(resp.data);
      } catch (error) {}
    };
    maxPosts();
  }, [boardId]);

  //

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value);
  };

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const searchFilter = useCallback(async () => {
    setLoading(true);
    try {
      await axios
        .get(`${API_URI}/posts?_limit=${limitPost}&board=${boardId}`)
        .then((resp) => {
          setTestList(
            _.filter(resp.data, (prop) =>
              searchKey
                ? prop.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
                : true,
            ),
          );
        })
        .catch(() => {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [boardId, searchKey, limitPost]);

  useEffect(() => {
    dispatch(createBoardId({id: mode ? mode : 3}));
  }, [dispatch, mode]);

  useEffect(() => {
    if (mobile) {
      setmaxPageNumberLimit(5);
    } else {
      setmaxPageNumberLimit(10);
    }
  }, [mobile]);

  useEffect(() => {
    const postFilter = async () => {
      setLoading(true);
      try {
        await axios
          .get(`${API_URI}/posts?_limit=${limitPost}&board=${boardId}`)
          .then((resp) => {
            setTestList(_.filter(resp.data, (prop) => true));
          })
          .catch(() => {});
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    postFilter();
  }, [value, boardId, setTestList, limitPost]);

  useEffect(() => {
    if (mode) {
      if (mode === 3) {
        setBoardId(3);
      } else {
        setBoardId(4);
      }
    }
  }, [mode]);

  const handleClick = (e: any) => {
    setcurrentPage(Number(e.target.id));
  };

  const bottomPost = _.sortBy(testList, (up) =>
    moment(up?.updated_at),
  ).reverse();

  const pages = [];
  if (bottomPost) {
    for (let i = 1; i <= Math.ceil(bottomPost.length / ITEMS_PER_PAGE); i++) {
      pages.push(i);
    }
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems =
    bottomPost && bottomPost.slice(indexOfFirstItem, indexOfLastItem);

  const {pageList} = usePagination({
    totalPage: Math.ceil(bottomPost.length / ITEMS_PER_PAGE),
    currentPage: currentPage,
    maxPageCount: mobile ? 5 : 10,
  });

  const renderPageNumbers = pageList.map((number) => {
    return (
      <li
        key={number}
        id={`${number}`}
        onClick={handleClick}
        className={`${currentPage === number ? 'active' : null}`}>
        {number}
      </li>
    );
  });

  // Pagination 다음 버튼
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    // else {
    //   setPageValue((currentPage - 1) * ITEMS_PER_PAGE);
    // }
  };

  // Pagination 이전 버튼
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    // else {
    //   setPageValue((currentPage - 1) * ITEMS_PER_PAGE);
    // }
  };

  const postFound = useMemo(() => {
    return _.sortBy(currentItems, (up) => moment(up.updated_at)).reverse();
  }, [currentItems]);

  const renderData = useCallback(
    (data: any[]) => {
      return (
        <>
          {_.map(data, (list) => {
            let title = list?.title;
            if (lang === 'ch' && list?.title_ch) {
              title = list?.title_ch;
            }
            if (lang === 'ru' && list?.title_ru) {
              title = list?.title_ru;
            }
            if (lang === 'vi' && list?.title_vi) {
              title = list?.title_vi;
            }

            return (
              <div
                className={classes.postDiv}
                onClick={() => {
                  history.push(`${list?.id}`);
                }}>
                <p style={{paddingRight: '10px', width: '8%', minWidth: 40}}>
                  {moment(list.updated_at).format('MM-DD')}
                </p>
                <p className={classes.postTitle}>{title}</p>
              </div>
            );
          })}
        </>
      );
    },
    [classes.postDiv, history, lang, classes.postTitle],
  );

  return (
    <div
      style={{
        marginBottom: 60,
      }}>
      <div className={classes.mobileFixed}>
        <Header
          langProp={(headerLang: LangCode) => {
            setHeaderLang(headerLang);
          }}
        />
        <div>
          <div className={classes.listul}>
            <Tabs
              variant="scrollable"
              scrollButtons="on"
              classes={{root: classes.listli, indicator: classes.test}}
              value={value}
              onChange={handleChange}>
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('채용정보')}
                onClick={() => {
                  history.push(`/info?page=1&boardId=1`);
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('인재공고')}
                onClick={() => {
                  history.push(`/info?page=1&boardId=2`, {mode: 2});
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('단체 모임방')}
                onClick={() => {
                  history.push('/community', {mode: 3});
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('나의 일자리 경험담_')}
                onClick={() => {
                  history.push('/community', {mode: 4});
                }}
              />
              <Tab
                disableRipple
                classes={{root: classes.listlink, selected: classes.selected}}
                label={t('인사노무_')}
                onClick={() => {
                  history.push('/feedback', {mode: 5});
                }}
              />
            </Tabs>
          </div>
        </div>
      </div>

      <div className={classes.topad}>
        <div className={classes.topPage}>
          <div>
            <div className={classes.topAdDiv}>
              <div className={classes.notice}>{t('주의사항')}</div>
              <div style={{maxWidth: 886}}>{t('허위 기재 및_')}</div>
              <div style={{marginTop: 10}}>{t('문제 소지 및_')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.bottomad}>
        <div className={classes.bottomadpr}>
          {loading ? (
            <div style={{textAlign: 'center'}}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </div>
          ) : postFound.length > 0 ? (
            postFound && renderData(postFound)
          ) : (
            <div
              style={{justifyContent: 'center', color: 'gray'}}
              className={classes.nonPostDiv}>
              {t('게시글이 존재하지 않습니다.')}
            </div>
          )}
        </div>
      </div>
      <div className={classes.pageUl}>
        <ul className={classes.paging}>
          <li>
            <button
              className={classes.pagingPrev}
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}>
              <ArrowBackIosIcon style={{fontSize: '14px', color: '#979696'}} />
            </button>
          </li>

          <div className="pageNum" style={{display: 'flex'}}>
            {renderPageNumbers}
          </div>

          <li>
            <button
              className={classes.pagingNext}
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}>
              <ArrowForwardIosIcon
                style={{fontSize: '14px', color: '#979696'}}
              />
            </button>
          </li>
        </ul>
        <div className={classes.onlyPcbtn}>
          <div className={classes.searchform}>
            <input
              className={classes.searchipt}
              type="text"
              id="keyword"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
              name="keyword"></input>
            <Button
              className={classes.searchbtn}
              onClick={() => {
                searchFilter();
              }}>
              {t('Search')}
            </Button>
          </div>
          <Button onClick={handleTop} className={classes.topBtnMobile}>
            <img src={TopIcon} alt="topIcon" style={{marginRight: '5px'}} />
            {t('맨위로')}
          </Button>
        </div>
      </div>
      <div className={classes.pageMobile}>
        <div></div>
        <div style={{textAlign: 'center', marginBottom: '6px'}}>
          <input
            className={classes.searchInputMobile}
            type="text"
            id="title_txt"
            name="title"
            onChange={(e) => setSearchKey(e.target.value)}
            value={searchKey}
          />
          <Button
            className={classes.searchBtnMobile}
            onClick={() => {
              searchFilter();
            }}>
            {t('Search')}
          </Button>
        </div>
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleTop} className={classes.onlyMobileButton}>
            <img src={TopIcon} alt="topIcon" style={{marginRight: '5px'}} />
            {t('맨위로')}
          </Button>
        </div>
      </div>
      <Footer />
      <div style={{display: 'none'}}></div>
    </div>
  );
};

export default Community;
